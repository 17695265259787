<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0'>
    <div class='border-2 border-gray-100 px-4 lg:px-8 py-8 rounded-lg shadow-md text-center font-light'>
      <p class='text-lg lg:text-xl'>Offline & Online Pre-registration deadline is <span :style='`color: ${eventMainThemeColor};`' class='block lg:inline text-lg lg:text-xl font-semibold'>Sep 26 (Mon), 2022</span></p>
      <p class='text-lg lg:text-xl mt-4'>Online Only Pre-registration deadline is <span :style='`color: ${eventMainThemeColor};`' class='block lg:inline text-lg lg:text-xl font-semibold'>Oct 7 (Fri), 2022</span></p>
      <div class='mx-auto text-base lg:text-lg mt-4 underline text-2xl lg:text-3xl font-bold'
        :style='`color: ${eventMainThemeColor};`'>
        <router-link :to='{ name: "Registration" }'>Go to Registration</router-link>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>
        Registration Guideline
      </h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            The registration guidelines of The 66<sup>th</sup> Annual Congress of the Korean Orthopaedic Association are intended to provide clear instructions for pre-registration on the website. Please carefully review the guidelines below before registration.
            It is only available to make pre-registration via the KOA 2022 website <a href='https://2022.koa.or.kr/' target='_blank'  class='underline'>https://2022.koa.or.kr</a>. After the deadline it is not available to register online and intended participants must register on-site.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Important Date</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Offline & Online Pre-registration Deadline: Sep 26 (Mon), 2022
            <div class='text-sm text-blue-900 opacity-75 ml-6'>* Based on Korean Standard Time (GMT +9hrs)</div>
          </li>
          <li class='mt-2 mb-8'>
            Online Only Pre-registration Deadline: Oct 7 (Fri), 2022
            <div class='text-sm text-blue-900 opacity-75 ml-6'>* Based on Korean Standard Time (GMT +9hrs)</div>
          </li>
          <li class='mt-2 mb-8'>
            On-site registration: During the Congress
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Pre-registration Process</h2>
      <div class='mt-2 w-full lg:w-2/3 pl-6 lg:pl-0'>
        <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/registration-guideline_1.png' class='w-full'>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Registration Fee</h2>
      <div class='w-full lg:w-2/3'>
        <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base'>
            <thead class='bg-gray-100'>
              <tr>
                <th class='w-1/3'>Category</th>
                <th class='w-1/3'>
                  Pre-registration<br>
                  <p class='text-xs'>Offline & Online: By Sep 26 (Mon), 2022<br>
                  Online Only: By Oct 7 (Fri), 2022</p>
                </th>
                <th class='w-1/3'>On-site registration<br>
                  <p class='text-xs'>During the Congress</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Physician Member<br>(KOA members only)</th>
                <td>USD 150 / KRW 150,000</td>
                <td>USD 160 / KRW 160,000</td>
              </tr>
              <tr class='bg-gray-100'>
                <th>Resident / Nurse / Army doctor / Researchers</th>
                <td>USD 100 / KRW 100,000</td>
                <td>USD 110 / KRW 110,000</td>
              </tr>
              <tr>
                <th>Non-Member</th>
                <td>USD 180 / KRW 180,000</td>
                <td>USD 190 / KRW 190,000</td>
              </tr>
              <tr class='bg-gray-100'>
                <th>Foreigners</th>
                <td>100USD</td>
                <td>-</td>
              </tr>
              <tr>
                <th>KOA Senior Member<br>(over 65 years old)</th>
                <td colspan='2'>Free</td>
              </tr>
            </tbody>
          </table>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            The registration dates are based on Korean Standard Time (GMT +9hrs)
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Entitlements</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Access to all congress session (International participants may attend online)
          </li>
          <li class='mt-2 mb-8'>
            Access to e-poster sessions
          </li>
          <li class='mt-2 mb-8'>
            Access to all exhibition halls (or virtual exhibitions)
          </li>
          <li class='mt-2 mb-8'>
            Lunch / Coffee break
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Method of Payment</h2>
      <div class='w-full lg:w-2/3'>
        <h3 class='mt-2 text-sm lg:text-base'>Payment of fees should be made using one of the following methods:</h3>
        <div class='text-xs text-blue-900 opacity-75 underline'>Note: Other methods of payment cannot be accepted.</div>
        <h3 class='mt-2 text-sm lg:text-base font-medium'>Credit Card</h3>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 pl-6 lg:pl-0'>
          <li class='mt-2 mb-8'>
            Payment by credit card is available only through the online registration.
          </li>
          <li class='mt-2 mb-8'>
            The following credit cards will be accepted: VISA, MasterCard and JCB.
          </li>
          <li class='mt-2 mb-8'>
            All service charges of credit cards are to be paid by the registrants.
          </li>
          <li class='mt-2 mb-8'>
            The actual debit amount is subject to change according to the exchange rate.
          </li>
          <li class='mt-2 mb-8'>
            The merchant name on your credit card statement will be 'Eximbay.'
          </li>
        </ul>
        <h3 class='mt-2 text-sm lg:text-base font-medium'>Wire Transfer</h3>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6 pl-6 lg:pl-0'>
          <li class='mt-2 mb-8'>
            All bank remittance charges are to be paid by the registrants.
          </li>
          <li class='mt-2 mb-8'>
            The sender's name should be the registrant's name. If the registrant’s name and the sender’s name are not identical, please send a copy of the wire transfer slip to the secretariat marked with the registrant’s name that appears on the wire transfer slip.
          </li>
          <li class='mt-2 mb-8'>
            Please ensure that the registrant's name is on the bank remittance receipt.
          </li>
          <li class='mt-2 mb-8'>
            Appropriate payment should be completed within the right period of registration. If you pay after the registration period, you will be required to pay the additional fee by wire transfer.
          </li>
        </ul>
        <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base'>
          <thead class='bg-gray-100'>
            <tr>
              <th class='w-1/2'>Category</th>
              <th class='w-1/2'>Bank Information (For Overseas)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>The receiving bank's name</th>
              <td>KB Kookmin Bank</td>
            </tr>
            <tr class='bg-gray-100'>
              <th>The receiving bank's branch name</th>
              <td>Yeouido-Rivertower Branch</td>
            </tr>
            <tr>
              <th>The receiving bank's The receiving bank's address</th>
              <td>61-5, Yeouido-Dong, Yeongdungpo-gu, Seoul, Korea.</td>
            </tr>
            <tr class='bg-gray-100'>
              <th>The receiving bank's SWIFT code</th>
              <td>CZNBKRSE</td>
            </tr>
            <tr>
              <th>Recipient's name</th>
              <td>The Korean Orthopaedic Association</td>
            </tr>
            <tr class='bg-gray-100'>
              <th>Recipient's address (in Korea)</th>
              <td>#905, A tower Centreville Asterium Seoul, 372, Hangang-daero, Yongsan-gu, Seoul, Korea (04323)</td>
            </tr>
            <tr class='bg-gray-100'>
              <th>Recipient's email address</th>
              <td>ortho@koa.or.kr</td>
            </tr>
            <tr class='bg-gray-100'>
              <th>Recipient's bank account number</th>
              <td>269101-04-008077</td>
            </tr>
          </tbody>
        </table>
        <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base w-full'>
          <thead class='bg-gray-100'>
            <tr>
              <th class='w-1/2'>Category</th>
              <th class='w-1/2'>Bank Information (For Korean)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>은행명</th>
              <td>KB 국민은행</td>
            </tr>
            <tr class='bg-gray-100'>
              <th>예금주</th>
              <td>대한정형외과학회</td>
            </tr>
            <tr>
              <th>계좌번호</th>
              <td>269101-04-008077</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Cancellation and Refund Policy</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <ul class='list-disc list-inside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
          <li class='mt-2 mb-8'>
            Cancellation must be notified to the secretariat in writing by email <a href='mailto:ortho@koa.or.kr' class='underline'>ortho@koa.or.kr</a>
          </li>
          <li class='mt-2 mb-8'>
            All refunds will be made after the congress.
          </li>
          <li class='mt-2 mb-8'>
            All bank service charges and all administration fees will be deducted from all congress registration refunds.
          </li>
        </ul>
      </div>
    </div>
    <div class='mt-8 lg:mt-24 flex flex-col lg:flex-row justify-start items-start'>
      <h2 class='mt-2 text-xl lg:text-2xl font-bold w-full lg:w-1/3'>Cut off Date</h2>
      <div class='w-full lg:w-2/3 pl-6 lg:pl-0'>
        <h3 class='mt-2 text-sm lg:text-base font-medium'>Offline & Online Pre-registration</h3>
        <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base w-full'>
          <thead class='bg-gray-100'>
            <tr>
              <th class='w-1/2'>By Sep 30 (Fri), 2022</th>
              <th class='w-1/2'>100% refund</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>From Oct 1 (Sat), 2022</th>
              <td>No refund</td>
            </tr>
          </tbody>
        </table>
        <h3 class='mt-2 text-sm lg:text-base font-medium'>Online Only Pre-registration</h3>
        <table class='mt-2 mb-8 registration-guideline-table border-t border-l text-center text-sm lg:text-base w-full'>
          <thead class='bg-gray-100'>
            <tr>
              <th class='w-1/2'>By Oct 7 (Fri), 2022</th>
              <th class='w-1/2'>100% refund</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>From Oct 8 (Sat), 2022</th>
              <td>No refund</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'RegistrationGuideline',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  }
}
</script>

<style type='scss' scoped>
  .registration-guideline-table tr {
    @apply border-b
  }

  .registration-guideline-table td {
    @apply border-r p-1 lg:p-2
  }

  .registration-guideline-table th {
    @apply border-r p-1 lg:p-2
  }
</style>
